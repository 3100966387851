<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr v-chakra w="full" />

    <c-box mt="30px">
      <c-text color="brand.900" text-transform="uppercase">
        Detail Program
      </c-text>
      <c-text font-size="24px" font-weight="700">Meal Plan</c-text>
    </c-box>
    <c-box mt="20px">
      <c-tabs
        is-fitted
        is-manual
        variant="enclosed"
        border-color="brand.900"
        variant-color="brand"
        :index="activeTabIndex"
        :defaultIndex="activeTabIndex"
        @change="handleTabChange"
      >
        <c-tab-list bg="#f2f2f2" roundedTop="lg">
          <c-tab
            v-for="(label, index) in [
              'Rencana Makan',
              'Rekomendasi Menu',
              'Profile Gizi',
            ]"
            :key="label"
            roundedTop="lg"
            font-size="18px"
            font-weight="700"
            :bg="index === activeTabIndex ? '#fff' : 'none'"
            :color="index === activeTabIndex ? 'brand.900' : '#c4c4c4'"
            outline-color="none"
          >
            {{ label }}
          </c-tab>
        </c-tab-list>

        <c-tab-panels
          border="1px"
          border-color="brand.900"
          roundedBottom="lg"
          p="20px"
        >
          <c-tab-panel>
            <RencanaMakan />
          </c-tab-panel>
          <c-tab-panel>
            <RekomendasiMenu />
          </c-tab-panel>
          <c-tab-panel>
            <ProfileGizi />
          </c-tab-panel>
        </c-tab-panels>
      </c-tabs>
    </c-box>
  </c-box>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumb.vue";
import RencanaMakan from "./rencana-makan.vue";
import RekomendasiMenu from "./rekomendasi-menu.vue";
import ProfileGizi from "./profile-gizi.vue";

export default {
  name: "AdminClientRencanaMakanIndex",
  components: { Breadcrumbs, RencanaMakan, RekomendasiMenu, ProfileGizi },
  data() {
    return {};
  },
  computed: {
    programId() {
      return this.$route.params.programId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    breadcrumbItems() {
      let clientId = this.clientId;
      let programId = this.programId;

      return [
        {
          label: "Manajemen Klien",
          href: this.$router.resolve({ name: "admin.clients" }).href,
        },
        {
          label: "Detail Klien",
          href: this.$router.resolve({
            name: "admin.clients-detail",
            params: { clientId },
          }).href,
        },
        {
          label: "Detail Program",
          href: this.$router.resolve({
            name: "admin.clients-programs",
            params: { clientId, programId },
          }).href,
        },
        { label: "Rencana Makan", href: null, isCurrent: true },
      ];
    },
    activeTabMap() {
      return {
        plan: 0,
        menu: 1,
        profile: 2,
      };
    },
    activeTabIndex() {
      return this.activeTabMap[this.moduleId] ?? 0;
    },
    activeTabName() {
      return Object.entries(this.activeTabMap).find(
        ([_, value]) => value === this.activeTabIndex
      )?.[0];
    },
  },
  methods: {
    handleTabChange(index) {
      let key = Object.entries(this.activeTabMap).find(
        ([_, value]) => value === index
      )?.[0];
      this.$router.push({
        params: { moduleId: key },
      });
    },
  },
  watch: {
    moduleId: {
      immediate: true,
      async handler(moduleId) {},
    },
  },
};
</script>
